import React from 'react'
import { Link } from 'gatsby'

const PartnerSection = () => {
  return (
    <section className="partner-section">
      <h2 className="parner-section__heading">Achieve top performance fueled by our vision and expertise</h2>
      <div>
      <Link className="btn" to={'/contacts'}>Start your project</Link>
      </div>
    </section>
  )
}

export default PartnerSection
