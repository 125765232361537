import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCode, faChartBar, faRobot , faDatabase, faCloud, faServer } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <section className="services-section" id="services">
      <div className="container">
        <h2>Our Services</h2>
        <p>From Software Development to Artificial Intelligence, our team of experts is prepared to guide and create your project from scratch.</p>
      </div>
      <div className="services-section__card-container">
        <div className="card">
          <div className="card__side card__side--front">
          <div className="card__head">
          <p className="text-center"><FontAwesomeIcon icon={faCode} size="2x" /></p>
          <h3 className="card__title">Software Engineering</h3>
          </div>
          </div>
          <div className="card__side card__side--back">
            <div className="card__description">
            <p className="text-small">Our process consists of breaking all business requirements into small development steps and designing the entire architecture based on best practices.</p>
            <p className="text-small">We take an agile approach to our projects and share consistent reporting with our partners. Once we deploy your application, we offer maintenance services through our customer service platform.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card__side card__side--front">
            <div className="card__head">
            <p className="text-center"><FontAwesomeIcon icon={faChartBar} size="2x" /></p>
            <h3 className="card__title">Data Science</h3>
            </div>
          </div>
          <div className="card__side card__side--back">
            <div className="card__description">
            <p className="text-small">We provide access to Business Intelligence and Advanced Analytics tools for a comprehensive view of any source or type of data.</p>
            <p className="text-small">Our data engineering team creates and advises out-of-the-box business-critical applications to process batch or live data streams for updated information and insights.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card__side card__side--front">
            <div className="card__head">
            <p className="text-center"><FontAwesomeIcon icon={faRobot} size="2x" /></p>
            <h3 className="card__title">AI & Machine Learning</h3>
            </div>
          </div>
          <div className="card__side card__side--back">
            <div className="card__description">
            <p className="text-small">Get the latest trends in Artificial Intelligence applied to your data streams and achieve automated intelligence. Set up effective AI solutions such as root cause analysis, recommendation engines, anomaly detection systems, or supply-demand forecast.</p>
            <p className="text-small">Our AI expert team seeks greatness in every project, and we constantly benchmark the latest technologies, always focusing on the cost/performance tradeoff.</p>
            </div>
          </div>
        </div> 
        <div className="card">
          <div className="card__side card__side--front">
            <div className="card__head">
            <p className="text-center"><FontAwesomeIcon icon={faDatabase} size="2x" /></p>
            <h3 className="card__title">Big Data</h3>
            </div>
          </div>
          <div className="card__side card__side--back">
            <div className="card__description">
            <p className="text-small">Our Big Data solutions allow you to innovate by exploring corporate data using cutting-edge technology.</p>
            <p className="text-small">Process heavy data workloads instantly and deliver automated flows by integrating reporting, notification, or orchestration features on top of your business platform. We provide cost-efficient approaches to get the most of your data from user journey/experience, history aggregations to custom KPI.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card__side card__side--front">
            <div className="card__head">
            <p className="text-center"><FontAwesomeIcon icon={faCloud} size="2x" /></p>
            <h3 className="card__title">Cloud Engineering</h3>
            </div>
          </div>
          <div className="card__side card__side--back">
            <div className="card__description">
            <p className="text-small">Engage with Forcera certified professionals to reduce downtime and infrastructure-related costs by migrating on-prem applications to cloud service environments.</p>
            <p className="text-small">Deploy your data applications to leading cloud service players to reach maximum stability and performance. Easily integrate new features to your virtual infrastructure, employing innovative like advanced analytics or AI machine learning cloud services.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card__side card__side--front">
            <div className="card__head">
            <p className="text-center"><FontAwesomeIcon icon={faServer} size="2x" /></p>
            <h3 className="card__title">Data Strategy</h3>
            </div>
          </div>
          <div className="card__side card__side--back">
            <div className="card__description">
            <p className="text-small">Whether you need to reestablish your entire game plan or integrate new components to an existing ecosystem, we assess, design and propose solid digital foundations according to your vision and needs.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services;