import React from 'react'

const HomeSection = () => {
  return (
    <section className="home-section container">
      <h3>
      All the best-run organizations are insight-driven. Be ahead of the game. <br /> Join forces with Forcera! 
      </h3>
      <p>
      We help our partners set up the perfect <span className="text-highlight"><span>digital strategy</span><span className="text-highlight-color"></span> </span> tailored to their business needs. <span className="text-highlight"><span>Optimize your workflow</span><span className="text-highlight-color"></span> </span>, <span className="text-highlight"><span>reduce time-to-market</span><span className="text-highlight-color"></span></span> or enhance the end-customer experience with our <span className="text-highlight"><span>high-performance solutions</span><span className="text-highlight-color"></span></span>.
      </p>
      <p>
      Whether you need to reestablish your <span className="text-highlight"><span>entire game plan</span><span className="text-highlight-color"></span></span> or integrate new components to an existing ecosystem, we <span className="text-highlight"><span>assess</span><span className="text-highlight-color"></span></span>, <span className="text-highlight"><span>design</span><span className="text-highlight-color"></span></span> and <span className="text-highlight"><span>propose</span><span className="text-highlight-color"></span></span> solid digital foundations according to your vision and needs.
      </p>
      <p>
      Achieve top performance <span className="text-highlight"><span>fueled</span><span className="text-highlight-color"></span></span> by our vision and expertise.
      </p>
    </section>
  )
}

export default HomeSection