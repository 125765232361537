import React from 'react'

const Accelerate = () => {
  return (
    <section className="section-accelerate">
            <div className="section-accelerate__container section-accelerate__container--100">
        <div className="section-accelerate__card section-accelerate__card--3">
        <div className="section-accelerate__content">
          <h2 className="section-accelerate__title section-accelerate__title--hide">
          Unleash the power of your data, improve operations and maximize efficiency
          </h2>
        </div>
        </div>
      </div>
    </section>
  )
}

export default Accelerate

{/* <div className="section-accelerate__container">
<div className="section-accelerate__card section-accelerate__card--1">
<div className="section-accelerate__content section-accelerate__content--al">
  <h2 className="section-accelerate__title section-accelerate__title--dark section-accelerate__title--al">
    Web Apps
  </h2>
</div>
</div>
</div>
<div className="section-accelerate__container">
<div className="section-accelerate__card section-accelerate__card--2">
<div className="section-accelerate__content section-accelerate__content--al">
  <h2 className="section-accelerate__title section-accelerate__title--al">
    Analytics & AI
  </h2>
</div>
</div>
</div> */}