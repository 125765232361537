import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Accelerate from "../components/accelerateSection"
import HomeSection from "../components/homeSection"
import Services from "../components/servicesSection"
import PartnerSection from "../components/partnerSection"

import "../styles/main.scss"




export default function Home() {

  return (
    <Layout >
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`, `Analytics`]}
      />
      <Header text={"We build impactful solutions that accelerate your business"}/>
      <Accelerate />
      <HomeSection />
      <Services />
      <PartnerSection />
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
